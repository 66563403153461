@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin-top: constant(safe-area-inset-top); /* Status bar height on iOS 11.0 */
  margin-top: env(safe-area-inset-top); /* Status bar height on iOS 11+ */
  margin-bottom: calc(4px + env(safe-area-inset-top));
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F1F4F9;
}

@media (min-width: 768px) 
{
   html
   {
       font-size: 1.3rem; 
   }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes flickerAnimation {
  0%   { opacity:0.2; }
  100% { opacity:0.6; }
}
.animate-breathe {
    animation: flickerAnimation 1.1s ease-in alternate infinite;
}

@keyframes flickerAnimationStrong {
  0%   { opacity:0.8; }
  100% { opacity:1.2; }
}
@-o-keyframes flickerAnimationStrong{
  0%   { opacity:0.8; }
  100% { opacity:1.2; }
}
@-moz-keyframes flickerAnimationStrong{
  0%   { opacity:0.8; }
  100% { opacity:1.2; }
}
@-webkit-keyframes flickerAnimationStrong{
  0%   { opacity:0.8; }
  100% { opacity:1.2; }
}
.animate-breathe-strong {
   -webkit-animation: flickerAnimationStrong 1.2s ease-in alternate infinite;
   -moz-animation: flickerAnimationStrong 1.2s ease-in alternate infinite;
   -o-animation: flickerAnimationStrong 1.2s ease-in alternate infinite;
    animation: flickerAnimationStrong 1.2s ease-in alternate infinite;
}

@keyframes flickerAnimationFast {
  0%  { opacity:1; }
  100%   { opacity:0.3; }
}
@-o-keyframes flickerAnimationFast{
  0%  { opacity:1; }
  100%   { opacity:0.3; }
}
@-moz-keyframes flickerAnimationFast{
  0%  { opacity:1; }
  100%   { opacity:0.3; }
}
@-webkit-keyframes flickerAnimationFast{
  0%  { opacity:1; }
  100%   { opacity:0.3; }
}
.animate-breathe-fast {
  -webkit-animation: flickerAnimationFast 0.6s ease-in alternate infinite;
  -moz-animation: flickerAnimationFast 0.6s ease-in alternate infinite;
  -o-animation: flickerAnimationFast 0.6s ease-in alternate infinite;
   animation: flickerAnimationFast 0.6s ease-in alternate infinite;
}

.marked {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.shine {
  position: relative;
}

.shine:after {
  animation: shine 3s ease-in-out  infinite;
  animation-fill-mode: forwards;  
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
  opacity: 0;
  transform: rotate(30deg);
  
  background: rgba(255, 255, 255, 0);
  background: linear-gradient(
    to right, 
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 70%,
    rgba(255, 255, 255, 1) 90%,
    rgba(255, 255, 255, 0) 100%
  );
}

.shine:active:after {
  opacity: 0;
}

@keyframes shine{
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 2s, 2s, 0.1s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}

@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
		-webkit-transform: translateY(0);
		-ms-transform:     translateY(0);
		transform:         translateY(0)
	}
	40% {
		-webkit-transform: translateY(-0.5em);
		-ms-transform:     translateY(-0.5em);
		transform:         translateY(-0.5em)
	}
  60% {
		-webkit-transform: translateY(-0.2em);
		-ms-transform:     translateY(-0.2em);
		transform:         translateY(-0.2em)
	}
}
.bounce {  
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-name: bounce;
  -moz-animation-name: bounce;
}

.MuiTooltip-tooltip	{
  font-size: 0.9rem;
}

.loading-ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1rem;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1rem;
  }
}


@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}
.fade-in { animation: fadeIn 1s  ; animation-delay: 5s; }
.fade-in-slower { animation: fadeIn 1s; animation-delay: 8;}

.waviy {
  position: relative;
  -webkit-box-reflect: below -2rem linear-gradient(transparent, rgba(0,0,0,.15));
}
.waviy span {
  font-family: 'Alfa Slab One', cursive;
  position: relative;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  animation: waviy 1s infinite;
  animation-delay: calc(.1s * var(--i));
  
}
@keyframes waviy {
  0%,40%,100% {
    transform: translateY(0)
  }
  20% {
    transform: translateY(-20px)
  }
}

.highlight {
  position: relative;
  z-index: 1;
}

.highlight::before {
  /* Highlight color */
  background-color: rgba(207, 255, 48, 0.373);
  content: "";
  position: absolute;
  width: calc(100% + 4px);
  height: 60%;
  left: -2px;
  bottom: 0;
  z-index: -1;
  animation: fillBarA 0.3s ease-out;
  transform: rotate(-2deg);
}


.fillBar {
  /* border: '1px solid #000'; */
  animation: fillBarA 0.1s ease-in;
  animation-fill-mode: both;
}
@keyframes fillBarA {
  0% { width: 0; }
  100% { width: 100%; }
}

@keyframes strikeFrames {
  0% { width: 0; }
  100% { width: 100%; }
}
.strike {
  position: relative;
}
.strike::after {
  content: ' ';
  position: absolute;
  top: 52%;
  left: 0;
  width: 95%;
  height: 1px;
  opacity: 0.7;
  background: #999;
  animation: strikeFrames 0.3s ease-out;
  animation-fill-mode: forwards; 
}


.dot {
  display: inline-block;
  animation-name: bouncing;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  &:nth-child(2) {
    animation-delay: 125ms;
  }
  &:nth-child(3) {
    animation-delay: 250ms;
  }
}

@keyframes bouncing {
  0% {
    transform: none;
  }
  
  33% {
    transform: translateY(-.2em);
  }
  
  66% {
    transform: none;
  }
}